import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import BulkView from '@/views/solutions/BulkView.vue'
import AboutView from '../views/AboutView.vue'
import ItTraining from '@/views/solutions/ItTraining.vue'
import ProjectManagement from '@/views/solutions/ProjectManagement.vue'
import ItSecurity from '@/views/solutions/ItSecurity.vue'
import ItHardware from '@/views/solutions/ItHardware.vue'
import InstallationView from '@/views/solutions/InstallationView.vue'
import SystemDevelopment from '@/views/solutions/SystemDevelopment.vue'
import ContactView from '../views/ContactView.vue'
ContactView
const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/communications',
    name: 'bulk',
    component: BulkView
  },
  {
    path: '/about',
    name: 'about',
    component: AboutView
  },
  {
    path: '/it-training',
    name: 'training',
    component: ItTraining
  },
  {
    path: '/it-project-management',
    name: 'project management',
    component: ProjectManagement
  },
  {
    path: '/it-security',
    name: 'It Security',
    component: ItSecurity
  },
  {
    path: '/it-hardware',
    name: 'It Hardware',
    component: ItHardware
  },
  {
    path: '/installations',
    name: 'Installation',
    component: InstallationView
  },
  {
    path: '/system-development',
    name: 'System Development',
    component: SystemDevelopment
  },
  {
    path: '/contact',
    name: 'contact',
    component: ContactView
  },
  
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
