<template>
        <h6 class="mx-2 text-end" @click="$emit('close-canvas')"><i class="fa-solid fs-2 fa-circle-xmark text-danger"></i></h6>
        <el-menu
          default-active="2"
          class="el-menu-vertical-demo"
          @select="handleSelect"
        >
        <el-menu-item index="1">
      <router-link @click="scrollToTop" to="/" class="link">
        Home
      </router-link>
    </el-menu-item>

    <el-sub-menu index="2">
      <template #title>Solutions</template>
      <el-menu-item index="2-1"><router-link to="/communications" @click="scrollToTop" class="link">Bulk Sms and Email Communication System</router-link></el-menu-item>
      <el-menu-item index="2-2"><router-link to="/it-project-management" @click="scrollToTop" class="link">IT Project management & Assurance</router-link></el-menu-item>
      <el-menu-item index="2-3"><router-link to="/it-training" @click="scrollToTop" class="link">IT Training</router-link></el-menu-item>
      <el-menu-item index="2-4"><router-link to="/it-security" @click="scrollToTop" class="link">IT  Security</router-link></el-menu-item>
      <el-menu-item index="2-5"><router-link to="/it-hardware" @click="scrollToTop" class="link">IT Hardware & Software sales & maintenance</router-link></el-menu-item>
      <el-menu-item index="2-6"><router-link to="/installations" @click="scrollToTop" class="link">CCTV  and Access control Installations</router-link></el-menu-item>
      <el-menu-item index="2-7"><router-link to="/system-development" @click="scrollToTop" class="link">System Development</router-link></el-menu-item>
    </el-sub-menu>
    <el-menu-item index="3"><router-link to="/about" @click="scrollToTop" class="link">About Us</router-link></el-menu-item>
    <el-menu-item index="4"><router-link to="/contact" @click="scrollToTop" class="link">Contact Us</router-link></el-menu-item>
        </el-menu>
  </template>
  
  <script>
  export default {
    setup (props, {emit}){
      const handleSelect = (key, keyPath)=>{
        emit('close-canvas')
      console.log(key, keyPath)
    }
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    }

      return{
        handleSelect,
        scrollToTop
      }
    }
  }
 
 
  </script>
  <style scoped>
  .link {
  text-decoration: none;
  font-weight: normal;
  color: black;
}
</style>