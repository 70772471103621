<template>
  <div
    class="pt-5 my-3 page-header border-radius-lg d-flex justify-content-center align-items-center rounded background-container-first"
    :style="{
      backgroundImage: 'url(' + require('@/assets/img/main.jpg') + ')',
      minHeight: '30vh',
    }"
  >
    <span style="background: #116fb7" class="mask opacity-6"></span>
    <div class="container-fluid">
      <div class="row justify-content-center">
        <div class="col-lg-8 text-center mx-auto my-auto">
          <h6 class="text-dark text-start hfont">
            <i class="fa-solid fa-forward" style="color: #ed6d08"></i
            >&nbsp;Home&nbsp;<i
              class="fa-solid fa-forward"
              style="color: #ed6d08"
            ></i
            >&nbsp;Contact Us
          </h6>
        </div>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="row mb-5 px-3 container mx-auto">
      <div class="col-md-4 dvt-heading-bordered">
        <h1 style="color: #70caec" class="text-start">Talk To Us</h1>
      </div>
    </div>
    <div class="row">
      <div class="col-md-4">
        <h6 class="text-sm ms-4 text-white text-start">Contact Us</h6>
        <div class="text-start mt-2">
          <span
            ><strong
              ><i class="fa fa-phone" style="color: #70caec"></i></strong></span
          ><span> 0746852668</span><br /><br />
          <span
            ><strong
              ><i
                class="fa fa-envelope"
                style="color: #70caec"
              ></i></strong></span
          ><span> info@africodeit.com</span><br /><br />
          <span
            ><i class="fa-solid fa-location-dot" style="color: #70caec"></i>
            Linkon Plaza, Kasarani</span
          >
        </div>
      </div>

      <div class="rounded col-md-8 mt-3" style="background-color: #f2f2f2">
        <form class="p-3">
          <div class="row gy-4">
            <div class="col-md-6">
              <div class="text-start">Name</div>
              <el-form-item>
                <el-input type="text" />
              </el-form-item>
            </div>

            <div class="col-md-6">
              <div class="text-start">Email</div>
              <el-form-item>
                <el-input type="text" />
              </el-form-item>
            </div>

            <div class="col-md-12">
              <div class="text-start">Subject</div>
              <el-form-item>
                <el-input type="text" />
              </el-form-item>
            </div>

            <div class="col-md-12">
              <div class="text-start">Message</div>
              <el-form-item>
                <el-input type="textarea" rows="6" />
              </el-form-item>
            </div>
            <div class="col-md-12 text-center">
              <el-button :style="'background:#ED6D08; color:white;'"
                >Send Message</el-button
              >
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>

  <div class="">
    <footer-component style="padding-bottom: 35px" />
  </div>
</template>
<script>
import FooterComponent from "@/components/FooterComponent.vue";

export default {
  components: {
    FooterComponent,
  },
};
</script>
<style scoped>
.background-container {
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  height: 160vh;
}
.dvt-heading-bordered {
  position: relative;
  padding-left: 24px;
  border: 0;
  border-left-width: 3px;
  border-style: solid;
  border-color: #ed6d08;
  padding-top: 12px;
  padding-bottom: 12px;
}
h1 {
  font-weight: 900;
}
.background-container-first {
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  height: 30vh;
}
.mn {
  margin-top: -2%;
}
@media (max-width: 768px) {
  .background-container-first {
    padding-top: 15%;
    min-height: 13vh !important;
  }
  .hfont {
    font-size: 12px;
  }
  .background-container-first {
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    height: 13vh;
  }
  .mn {
    margin-top: -6%;
  }
  .background-container {
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    height: 260vh;
  }
}
.dvt-heading-bordered {
  position: relative;
  padding-left: 24px;
  border: 0;
  border-left-width: 3px;
  border-style: solid;
  border-color: #ed6d08;
  padding-top: 12px;
  padding-bottom: 12px;
}
</style>
