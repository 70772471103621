<template>
     <div class="container">
        <div class="row align-items-center">
          <div class="col-lg-12">
            <div class="row justify-content-center">
              <div class="col-md-4 mt-4">
                <div class="card border-0 rounded shadow">
                  <div class="mt-5">
                    <i class="card-img-top fa-6x fa-regular fa-paper-plane" style="color:#70CAEC;"></i>
                  </div>
                 
                  <!-- <img
                    class="card-img-top"
                    v-lazy="bulkImg"
                    alt="Card image cap"
                  /> -->
                  <div class="card-body">
                    <h5 class="card-title"><b>Bulk Sms And Emails</b></h5>
                    <p class="card-text">
                      Maximize outreach instantly with our Bulk SMS & Email – drive engagement effortlessly
                    </p>
                    <router-link @click="scrollToTop" to="/communications">
                      <el-button :style="'background:#ED6D08; color:white;'"
                        >Read More</el-button
                      ></router-link
                    >
                  </div>
                </div>
              </div>
              <div class="col-md-4 mt-4">
                <div class="card border-0 rounded shadow">
                  <div class="mt-5">
                    <i class="card-img-top fa-6x fa-regular fa-calendar-check" style="color:#70CAEC;"></i>
                  </div>
                  <!-- <img
                    class="card-img-top"
                    v-lazy="projectImg"
                    alt="Card image cap"
                  /> -->
                  <div class="card-body">
                    <h5 class="card-title"><b>IT Project management & Assurance</b></h5>
                    <p class="card-text">
                      Flawless project delivery assured – leave the management to us!
                    </p>
                    <router-link @click="scrollToTop" to="/it-project-management">
                      <el-button :style="'background:#ED6D08; color:white;'"
                        >Read More</el-button
                      ></router-link
                    >
                  </div>
                </div>
              </div>
              <div class="col-md-4 mt-4">
                <div class="card border-0 rounded shadow">
                  <div class="mt-5">
                    <i class="card-img-top fa-6x fa-solid fa-chalkboard-user" style="color:#70CAEC;"></i>
                  </div>
                 
                  <!-- <img
                    class="card-img-top"
                    v-lazy="trainingImg"
                    alt="Card image cap"
                  /> -->
                  <div class="card-body">
                    <h5 class="card-title"><b>IT Training</b></h5>
                    <p class="card-text">
                      Empower your team for success – invest in our IT Training programs!
                    </p>
                    <router-link @click="scrollToTop" to="/it-training">
                      <el-button :style="'background:#ED6D08; color:white;'"
                        >Read More</el-button
                      ></router-link
                    >
                  </div>
                </div>
              </div>
            </div>
            <div class="row justify-content-start mt-2">
              <div class="col-md-3 mt-4">
                <div class="card border-0 rounded shadow">
                  <div class="mt-5">
                    <i class="card-img-top fa-6x fas fa-shield-alt" style="color:#70CAEC;"></i>
                  </div>
                  
                  <!-- <img
                    class="card-img-top"
                    v-lazy="securityImg"
                    alt="Card image cap"
                  /> -->
                  <div class="card-body">
                    <h5 class="card-title"><b>IT  Security</b></h5>
                    <p class="card-text">
                      Stay secure, stay ahead – trust our comprehensive IT Security solutions!
                    </p>
                    <router-link @click="scrollToTop" to="/it-security">
                      <el-button :style="'background:#ED6D08; color:white;'"
                        >Read More</el-button
                      ></router-link
                    >
                  </div>
                </div>
              </div>
              <div class="col-md-3 mt-4">
                <div class="card border-0 rounded shadow">
                  <div class="mt-5">
                    <i class="card-img-top fa-6x fa-solid fa-computer" style="color:#70CAEC;"></i>
                  </div>
                  
                  <!-- <img
                    class="card-img-top"
                    v-lazy="hardwareImg"
                    alt="Card image cap"
                  /> -->
                  <div class="card-body">
                    <h5 class="card-title"><b>IT Hardware & Software sales & maintenance</b></h5>
                    <p class="card-text">
                      Upgrade seamlessly – from sales to maintenance, we've got you covered!
                    </p>
                    <router-link @click="scrollToTop" to="/it-hardware">
                      <el-button :style="'background:#ED6D08; color:white;'"
                        >Read More</el-button
                      ></router-link
                    >
                  </div>
                </div>
              </div>
              <div class="col-md-3 mt-4">
                <div class="card border-0 rounded shadow">
                  <div class="mt-5">
                    <i class="card-img-top fa-6x fa-solid fa-eye" style="color:#70CAEC;"></i>
                  </div>
                  <!-- <img
                    class="card-img-top img-fluid"
                    v-lazy="cctvImg"
                    alt="Card image cap"
                  /> -->
                  <div class="card-body">
                    <h5 class="card-title"><b>CCTV  and Access control Installations</b></h5>
                    <p class="card-text">
                      Protect with precision – our CCTV & Access Control ensure safety, guaranteed.
                    </p>
                    <router-link @click="scrollToTop" to="/installations">
                      <el-button :style="'background:#ED6D08; color:white;'"
                        >Read More</el-button
                      ></router-link
                    >
                  </div>
                </div>
              </div>
              <div class="col-md-3 mt-4">
                <div class="card border-0 rounded shadow">
                  <div class="mt-5">
                    <i class="card-img-top fa-6x fa-solid fa-code" style="color:#70CAEC;"></i>
                  </div>
                  <!-- <img
                    class="card-img-top img-fluid"
                    v-lazy="webImg"
                    alt="Card image cap"
                  /> -->
                  <div class="card-body">
                    <h5 class="card-title"><b>System Development</b></h5>
                    <p class="card-text">
                      Innovate effortlessly – our custom solutions drive business growth.
                    </p>
                    <router-link @click="scrollToTop" to="/system-development">
                      <el-button :style="'background:#ED6D08; color:white;'"
                        >Read More</el-button
                      ></router-link
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
</template>
<script>
import trainingImg from "@/assets/img/training.jpg"
import bulkImg from "@/assets/img/send.jpg"
import projectImg from "@/assets/img/project.jpg"
import hardwareImg from "@/assets/img/hardware.jpg"
import webImg from "@/assets/img/web.jpg"
import securityImg from "@/assets/img/security.jpg"
import cctvImg from "@/assets/img/cctv.jpg"




export default {
    data(){
    return{
      trainingImg,
      bulkImg,
      projectImg,
      hardwareImg,
      webImg,
      securityImg,
      cctvImg
    }
    
  },
  setup(){
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    }

    return{
      scrollToTop
    }
  },
}
</script>
<style scoped>
.row {
    display: flex;
    flex-wrap: wrap;
}
.card {
    height: 100%;
    display: flex;
    flex-direction: column;
}
.card-img-top {
  width: 100%; 
  height: 150px; 
  object-fit: cover; 
}
</style>