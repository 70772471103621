<template>
    <div
      class="pt-5 my-3 page-header border-radius-lg d-flex justify-content-center align-items-center rounded background-container-first"
      :style="{
        backgroundImage: 'url(' + require('@/assets/img/main.jpg') + ')',
        minHeight: '30vh',
      }"
    >
      <span style="background: #116fb7" class="mask opacity-6"></span>
      <div class="container-fluid">
        <div class="row justify-content-center">
          <div class="col-lg-8 text-center mx-auto my-auto">
            <h6  class="text-dark text-start hfont">
              <i class="fa-solid fa-forward" style="color:#ED6D08"></i>&nbsp;Home&nbsp;<i
                class="fa-solid fa-forward" style="color:#ED6D08"
              ></i
              >&nbsp;About Us
            </h6>
          </div>
        </div>
      </div>
    </div>
   
    <div  class="background-container"
      :style="{
        backgroundImage: 'url(' + require('@/assets/img/about.jpg') + ')',
      }"
        >

      <div class="container-fluid bg-white pb-2  opacity-75 mn" >
        <div class="row align-items-center mb-5 container mx-auto">
          <div class="col-lg-5 px-5 mx-auto mt-5"><img src="@/assets/img/vision.jpg" alt="" class="img-fluid mb-4 mb-lg-0"></div>
            <div class="col-lg-6">
                <div class="row  container mx-auto mb-3">
              <div class="col-md-12 dvt-heading-bordered">
                <h1 style="color:#70CAEC" class="text-start">Our Vision</h1>
              </div>
            </div>
                <h6 class="font-italic mb-4 text-start px-3"><strong>To lead the Information Technology landscape with transformative solutions that redefine possibilities, inspire progress and drive meaningful impact. <br>
                  By leveraging cutting-edge technologies, fostering collaborative partnerships and delivering unparalleled service excellence, we aspire to be the trusted partner of choice for businesses in the continent, shaping the future of technology.</strong></h6>
            </div>
        </div>
      </div>

      <div class="container-fluid bg-white  opacity-75 pb-5" style="padding-top: -5%;">
        <div class="row align-items-center container mx-auto">
          <div class="col-lg-6 order-2 order-lg-1">
            <div class="row container mx-auto mb-3">
              <div class="col-md-12 dvt-heading-bordered">
                <h1 style="color:#70CAEC" class="text-start">Our Mission</h1>
              </div>
            </div>
              <h6 class="font-italic  mb-4 text-start px-3"><strong>Providing comprehensive and customizable solutions and services that empower businesses to thrive in the digital age <br>
                  Through innovative communication systems, tailored software solutions and expert consultation, we aim to drive efficiency, growth and success for our clients.</strong></h6>
          </div>
              <div class="col-lg-5 px-5 mx-auto order-1 order-lg-2"><img src="@/assets/img/mission.jpg"  alt="" class="img-fluid mb-4 mb-lg-0"></div>
        </div>
      </div>


      <div class="container-fluid bg-white pb-2 opacity-75">
        <div class="row align-items-center mb-5 container mx-auto">
          <div class="col-lg-5 px-5 mx-auto"><img src="@/assets/img/core.jpg" alt="" class="img-fluid mb-lg-0"></div>
            <div class="col-lg-6 text-end">
              <div class="row px-3 container mx-auto">
                <div class="col-md-12 dvt-heading-bordered">
                  <h1 style="color:#70CAEC" class="text-start">Our Core Values</h1>
                </div>
              </div>
                <p class="font-italic text-muted mb-4">
                  <ul class="text-start" >
                      <li><strong>Customer-centric Approach</strong></li>
                      <li><strong>Quality and Excellence</strong></li>
                      <li><strong>Reliability and Trustworthiness</strong></li>
                      <li><strong>Collaboration and Partnerships</strong></li>
                      <li><strong>Adaptability and Flexibility</strong></li>
                      <li><strong>Innovation</strong></li>
                  </ul>
              </p>
            </div>
        </div>
      </div>

      <div class="">
        <footer-component style="padding-bottom:35px;"/>
      </div>
      
    </div>
 
</template>
<script>
import FooterComponent  from "@/components/FooterComponent.vue"

export default {
  components:{
    FooterComponent
  }
}
</script>
<style scoped>
.background-container {
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  /* Set the height to 100vh to make sure the background covers the entire viewport */
  height: 160vh;
  /* overflow-y: auto; */
}
.dvt-heading-bordered {
  position: relative;
  padding-left: 24px;
  border: 0;
  border-left-width: 3px;
  border-style: solid;
  border-color: #ED6D08;
  padding-top: 12px;
  padding-bottom: 12px;
}
h1{
  font-weight: 900;
}
.background-container-first{
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  height: 30vh;
}
.mn{
  margin-top: -2%;
}
@media (max-width: 768px) { 
    .background-container-first {
      padding-top: 15%;
      min-height: 13vh !important; 
    }
    .hfont{
      font-size: 12px;
    }
    .background-container-first{
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    height: 13vh;
  }
  .mn{
    margin-top: -6%;
  }
  .background-container {
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  /* Set the height to 100vh to make sure the background covers the entire viewport */
  height: 260vh;
  /* overflow-y: auto; */
}
  }

</style>