<template>
      <footer class="footer pt-5 mb-0" style="background-color: #ed6d08; ">
    <div class="container">
      <div class=" row">
        <div class="col-md-3 mb-4 mx-auto">
          <div>
            <router-link @click="scrollToTop" to="/">
              <img src="@/assets/africodeit.jpeg" class="mb-3 footer-logo" alt="main_logo" style="width:12rem;">
            </router-link>
            <h6 class="font-weight-bolder mb-4">Crafting Digital Solutions, Elevating Businesses</h6>
          </div>
          <div class="ms-4">
            <ul class="d-flex flex-row nav">
              <li class="nav-item">
                <router-link @click="scrollToTop" class="nav-link pe-1" to="" target="_blank">
                  <i class="fa-brands fa-instagram text-white text-lg opacity-8"></i>
                </router-link>
              </li>
              <li class="nav-item">
                <router-link @click="scrollToTop" class="nav-link pe-1" to="" target="_blank">
                  <i class="fa-brands fa-x-twitter text-white text-lg opacity-8"></i>
                </router-link>
              </li>
              <li class="nav-item">
                <router-link @click="scrollToTop" class="nav-link pe-1" to="" target="_blank">
                  <i class="fa-brands fa-facebook text-white text-lg opacity-8"></i>
                </router-link>
              </li>
              <li class="nav-item">
                <router-link @click="scrollToTop" class="nav-link pe-1" to="" target="_blank">
                  <i class="fa-brands fa-linkedin text-white text-lg opacity-8"></i>
                </router-link>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-md-2 col-sm-6 col-6 mb-4">
          <div>
            <h6 class="text-sm text-white">Solutions</h6>
            <ul class="flex-column ms-n3 nav">
              <li class="nav-item">
                <router-link @click="scrollToTop" class="nav-link" to="/it-training" >
                  IT Training
                </router-link>
              </li>
              <li class="nav-item">
                <router-link @click="scrollToTop" class="nav-link" to="/it-security" >
                  IT  Security
                </router-link>
              </li>
              <li class="nav-item">
                <router-link @click="scrollToTop" class="nav-link" to="/system-development" >
                  System Development
                </router-link>
              </li>
              <li class="nav-item">
                <router-link @click="scrollToTop" class="nav-link" to="/installations" >
                  CCTV  and Access control Installations
                </router-link>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-md-3 col-sm-6 col-6 mb-4">
          <div>
            <h6 class="text-sm text-white">Solutions</h6>
            <ul class="flex-column ms-n3 nav">
              <li class="nav-item">
                <router-link @click="scrollToTop" class="nav-link" to="/communications" >
                  Bulk Sms and Email Communication System
                </router-link>
              </li>
              <li class="nav-item">
                <router-link @click="scrollToTop" class="nav-link" to="/it-project-management" >
                  IT Project management & Assurance
                </router-link>
              </li>
              <li class="nav-item">
                <router-link @click="scrollToTop" class="nav-link" to="/it-hardware" >
                  IT Hardware & Software sales & maintenance
                </router-link>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-md-2 col-sm-6 col-6 mb-4">
          <div>
            <h6 class="text-sm text-white">Links</h6>
            <ul class="flex-column ms-n3 nav">
              <li class="nav-item">
                <router-link @click="scrollToTop" class="nav-link" to="/" >
                  Home
                </router-link>
              </li>
              <li class="nav-item">
                <router-link @click="scrollToTop" class="nav-link" to="/about" >
                  About Us
                </router-link>
              </li>
              <li class="nav-item">
                <router-link @click="scrollToTop" class="nav-link" to="/contacts" >
                  Contact Us
                </router-link>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-md-2 col-sm-6 col-6 mb-4 me-auto">
          <div>
            <h6 class="text-sm ms-4 text-white text-start ">Contact Us</h6>
            <div class="text-start mt-2">
              <span><strong><i class="fa fa-phone text-white" ></i></strong></span><span>  0746852668</span><br><br>
              <span><strong><i class="fa fa-envelope text-white" ></i></strong></span><span>  info@africodeit.com</span><br><br>
              <span><i class="fa-solid fa-location-dot text-white" ></i>  Linkon Plaza, Kasarani</span>
            </div>
          </div>
        </div>
        <div class="col-12">
          <div class="text-center">
            <p class="text-dark my-4 text-sm font-weight-normal">
              All rights reserved. Copyright ©  {{ new Date().getFullYear() }}, Africode IT Solutions Limited.
            </p>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>
<script setup>
 const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    }
</script>

<style scoped>
.nav-link{
  text-decoration: none;
  color: black;
}
</style>