<template>
    <div class="container-fluid">
        <div class="top-info rounded d-flex justify-content-between align-items-center">
        <div class="d-flex justify-content-start  gap-2 py-2 ps-2">
            <div>
                <span><i class="fa fa-envelope" style="color:#70CAEC;"></i> &nbsp; info@africodeit.com</span>
            </div>
            <div>
                <span><i class="fa fa-phone" style="color:#70CAEC;"></i>&nbsp; 0746852668</span>
            </div>
            <div>
                <span><i class="fa-solid fa-location-dot" style="color:#70CAEC;"></i>&nbsp; Linkon Plaza, Kasarani</span>
            </div>
        </div>
        <div class="d-flex justify-content-end gap-3 px-3">
            <div>
                <span><i class="fa-brands fa-x-twitter"></i></span>
            </div>
            <div>
                <span><i class="fa-brands fa-instagram"></i></span>
            </div>
            <div>
                <span><i class="fa-brands fa-facebook"></i></span>
            </div>
            <div>
                <span><i class="fa-brands fa-linkedin"></i></span>
            </div>
        </div>
    </div>
    </div>
    
</template>

<script>

export default {
    setup() {
        
    },
}
</script>
<style>
.top-info{
    background-color:#ED6D08;
    margin-top:-1.5%;
    color:white;
}
</style>