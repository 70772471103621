<template>
    <div>
      <div
        class="pt-5 my-3 page-header border-radius-lg d-flex justify-content-center align-items-center background-container-first"
        :style="{
          backgroundImage: 'url(' + require('@/assets/img/main.jpg') + ')',
          minHeight: '30vh',
        }"
      >
        <span style="background: #116fb7" class="mask opacity-6"></span>
        <div class="container-fluid">
          <div class="row justify-content-center">
            <div class="col-lg-8 text-center mx-auto my-auto">
              <h6 class="text-dark text-start hfont">
                <i class="fa-solid fa-forward" style="color:#ED6D08"></i>&nbsp;Home &nbsp;<i
                  class="fa-solid fa-forward" style="color:#ED6D08"
                ></i
                >&nbsp;Solutions &nbsp;<i class="fa-solid fa-forward" style="color:#ED6D08"></i
                >&nbsp;IT Training&nbsp;
              </h6>
            </div>
          </div>
        </div>
      </div>
  
      <section class="py-7 mb-5">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-lg-6">
              <div class="row mb-5">
            <div class="col-md-12 dvt-heading-bordered">
              <h1 style="color:#70CAEC" class="">IT Training</h1>
            </div>
          </div>
          <div>
              <p class="text-start">
                Empower your team with the skills they need to excel in today's digital landscape through our comprehensive IT Training programs! From cybersecurity to cloud computing, our expert instructors will equip your staff with the latest knowledge and best practices. Whether it's in-person workshops or convenient online courses, we offer flexible training options to fit your schedule and budget. Invest in your team's growth and watch your organization thrive!
              </p>
          </div>
              
            </div>
            <div class="col-lg-4 ms-auto mt-lg-0 mt-4">
              <div class="card border-0">
                <div
                  class=""
                >
                    <img
                      src="@/assets/img/training.jpg"
                      alt="img-colored-shadow"
                      class="img-fluid border-radius-lg"
                    />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <footer-component/>
    </div>
  </template>
  <script>
  import FooterComponent  from "@/components/FooterComponent.vue"
  
  export default {
    components:{
      FooterComponent
    }
  }
  </script>
  
  <style scoped>
  .dvt-heading-bordered {
    position: relative;
    padding-left: 24px;
    border: 0;
    border-left-width: 3px;
    border-style: solid;
    border-color: #ED6D08;
    padding-top: 12px;
    padding-bottom: 12px;
  }
  @media (max-width: 768px) { 
    .background-container-first {
      padding-top: 15%;
      min-height: 13vh !important; 
    }
    .hfont{
      font-size: 12px;
    }
  }
  
  </style>
  