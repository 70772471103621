<template>
  <body class="bg-light">
    <nav>
    <div class="top-info">
      <top-info/>
    </div>
   
    <top-navbar/>
  </nav>
  <div class="page-header">
    <router-view/>
  </div>
  </body>
  
</template>

<script>
import TopNavbar from '@/components/navigation/TopNavbar.vue'
import TopInfo from '@/components/navigation/TopInfo.vue'
export default {
  components:{
    TopNavbar,
    TopInfo
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  position: fixed; /* Set the position to fixed */
  top: 0; /* Position it at the top of the viewport */
  left: 0; /* Align it to the left side of the viewport */
  width: 100%; /* Make it take the full width of the viewport */
  padding: 30px 10px 0px 10px; /* Add padding as needed */
  background-color: #ffffff; /* Set background color */
  z-index: 1000; 
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
.top-info{
  display:none;
}
.body{
  font-family: Roboto,"Helvetica Neue",sans-serif;
}
@media screen and (min-width:768px){
    .top-info{
    display: block;
}
}

@media screen and (min-width:1281px){
  .page-header{
  margin-top:2.3%;
}
}
@media screen and (min-width: 1025px) and (max-width: 1280px) {
  .page-header {
    margin-top: 5.3%;
  }
}
@media screen and (min-width: 913px) and (max-width: 1024px) {
  .page-header {
    margin-top: 7%;
  }
}
@media screen and (min-width: 821px) and (max-width: 912px) {
  .page-header {
    margin-top: 5%;
  }
}
@media screen and (min-width: 769px) and (max-width: 820px) {
  .page-header {
    margin-top: 6.5%;
  }
}
@media screen and (min-width: 541px) and (max-width: 768px) {
  .page-header {
    margin-top: 12.5%;
  }
}
@media screen and (min-width: 431px) and (max-width: 540px) {
  .page-header {
    margin-top: 3.8%;
  }
}
@media screen and (min-width: 413px) and (max-width: 430px) {
  .page-header {
    margin-top: 8.5%;
  }
}

@media screen and (min-width: 361px) and (max-width: 412px) {
  .page-header {
    margin-top: 10.2%;
  }
}
@media screen and (max-width: 360px) {
  .page-header {
    margin-top: 12%;
  }
}

</style>
