<template>
  <div class="containe body">
    <div
      class="pt-5 my-3 page-header border-radius-lg d-flex justify-content-center align-items-center  background-container-first"
      :style="{
        backgroundImage: 'url(' + require('@/assets/img/main.jpg') + ')',
        minHeight: '60vh',
      }"
    >
      <span style="background: #000" class="mask opacity-100"></span>
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-8 text-center mx-auto my-auto mb-5">
            <h1 class="text" style="color:#ed6d08">Crafting Digital Solutions, Elevating Businesses</h1>
            <p class="lead mb-4 text-dark opacity-8">
              We Provide comprehensive and customizable solutions and services that empower businesses to thrive in the digital age
            </p>
          </div>
        </div>
      </div>
    </div>

    <!---------------------------------------------------------------products------------------------------------------->

    <section class="products  mb-5">
     <product-view/>
    </section>
    <!---------------------------------------------------------------End products------------------------------------------->

    <!---------------------------------------------------------------Why Choose Us------------------------------------------->
    <div
    class="background-container"
      :style="{
        backgroundImage: 'url(' + require('@/assets/img/code.jpg') + ')',
      }"
    >
    <section class=" mt-5 py-5">
      <div class="container-fluid  bg-white p-3 ">
        <div class="row mb-5 px-3 container mx-auto">
          <div class="col-md-4 dvt-heading-bordered">
            <h1 style="color:#70CAEC" class="text-start">Why Choose Us</h1>
          </div>
        </div>
        <div class="row align-items-center container mx-auto">
          <div class="col-lg-12 mx-auto">
            <div class="row justify-content-start">
              <div class="col-md-6">
                <div class="info">
                  <h5 class="text-start"><i class="fa-solid fa-users" style="color: #ed6d08"></i>&nbsp;Client-Centric Focus</h5>
                  <p class="text-start">
                    Our unwavering commitment to client satisfaction drives everything we do. We prioritize listening to our clients, understanding their needs and delivering personalized solutions that exceed their expectations.
                  </p>
                </div>
              </div>
              <div class="col-md-6">
                <div class="info">
                  <h5 class="text-start"><i class="fa-regular fa-lightbulb" style="color: #ed6d08"></i>&nbsp;Tailored Solutions</h5>
                  <p class="text-start">
                    We offer fully customizable IT solutions tailored to the unique needs and challenges of each client, ensuring that our products and services align perfectly with their business objectives.

                  </p>
                </div>
              </div>
            </div>
            <div class="row justify-content-start mt-4">
              <div class="col-md-6">
                <div class="info">
                  <h5 class="text-start"><i class="fas fa-graduation-cap" style="color: #ed6d08"></i>&nbsp;Expert Consultation</h5>
                  <p class="text-start">
                    With a team of experienced consultants and industry experts, we provide valuable insights and strategic guidance to help our clients navigate the complexities of Information Technology, maximize their investments and achieve their business goals.

                  </p>
                </div>
              </div>
              <div class="col-md-6">
                <div class="info">
                  <h5 class="text-start"><i class="fas fa-hands-helping" style="color: #ed6d08"></i>&nbsp;Reliability and Support</h5>
                  <p class="text-start">
                    We pride ourselves on the reliability and robustness of our solutions, backed by proactive support and responsive customer service to ensure our clients' systems run smoothly and efficiently at all times.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
<!------------------------------------------------------------End Why Choose Us------------------------------------------->

<!------------------------------------------------------------Footer------------------------------------------------------>
   <footer-component style="padding-bottom:35px;"/>
  </div>
  </div>
</template>
<script>
import FooterComponent  from "@/components/FooterComponent.vue"
import ProductView from '@/components/ProductView.vue'

export default {
  data(){
    return{
   
    }
    
  },
  components:{
    FooterComponent,
    ProductView
   
  },
  setup(){
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    }
    return{
      scrollToTop
    }
  }

}
</script>

<style scoped>
.page-header {
  min-height: 50%;
}
.products {
  margin-top: -5%;
}
.dvt-heading-bordered {
  position: relative;
  padding-left: 24px;
  border: 0;
  border-left-width: 3px;
  border-style: solid;
  border-color: #ED6D08;
  padding-top: 12px;
  padding-bottom: 12px;
}
.text-capitalize {
  text-transform: capitalize !important;
}

.background-container {
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  /* Set the height to 100vh to make sure the background covers the entire viewport */
  height: 95vh;
  /* overflow-y: auto; */
}

.background-container-first{
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  height: 60vh;
}
.body{
  font-family: Roboto,"Helvetica Neue",sans-serif;
}
.p{
  font-weight: 300;
}

@media (max-width: 768px) { 
    .products {
    margin-top: -20%;
  }
   
  }



</style>
