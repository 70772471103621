<template>
  <nav class="navbar navbar-expand-lg">
    <img
      style="width: 180px"
      src="@/assets/africodeit.jpeg"
      alt="Element logo"
    />

    <p class="navbar-toggler tog mt-3" @click.prevent="sidebar">
      <i class="fa-solid fs-2 fa-bars" style="color: #ed6d08"></i>
    </p>
    <div class="collapse navbar-collapse" id="navbarText">
      <div class="d-flex col-md-12 gap-3 justify-content-end">
        <el-menu
          :default-active="activeIndex"
          class="el-menu-demo mb-0"
          mode="horizontal"
          :collapse="false"
          :ellipsis="false"
          @select="handleSelect"
        >
         
          <el-menu-item index="1">
            <router-link @click="scrollToTop" to="/" class="link"> Home </router-link>
          </el-menu-item>

          <el-sub-menu index="2">
            <template #title>Solutions</template>
            <el-menu-item index="2-1"
              ><router-link @click="scrollToTop" to="/communications" class="link"
                >Bulk Sms and Email Communication System</router-link
              ></el-menu-item
            >
            <el-menu-item index="2-2"
              ><router-link @click="scrollToTop" to="/it-project-management" class="link"
                >IT Project management & Assurance</router-link
              ></el-menu-item
            >
            <el-menu-item index="2-3"
              ><router-link @click="scrollToTop" to="/it-training" class="link"
                >IT Training</router-link
              ></el-menu-item
            >
            <el-menu-item index="2-4"
              ><router-link @click="scrollToTop" to="/it-security" class="link"
                >IT Security</router-link
              ></el-menu-item
            >
            <el-menu-item index="2-5"
              ><router-link @click="scrollToTop" to="/it-hardware" class="link"
                >IT Hardware & Software sales & maintenance</router-link
              ></el-menu-item
            >
            <el-menu-item index="2-6"
              ><router-link @click="scrollToTop" to="/installations" class="link"
                >CCTV and Access control Installations</router-link
              ></el-menu-item
            >
            <el-menu-item index="2-7"
              ><router-link @click="scrollToTop" to="/system-development" class="link"
                >System Development</router-link
              ></el-menu-item
            >
          </el-sub-menu>
          <el-menu-item index="3"
            ><router-link @click="scrollToTop" to="/about" class="link"
              >About Us</router-link
            ></el-menu-item
          >
          <el-menu-item index="4"
            ><router-link @click="scrollToTop" to="/contact" class="link"
              >Contact Us</router-link
            ></el-menu-item
          >
        </el-menu>
      </div>
    </div>
  </nav>
 
  <off-canvas
  v-if="showCanvas"
  @close-canvas="showCanvas=false"/>
</template>

<script>
import { ref } from 'vue'
import OffCanvas from "./OffCanvas.vue"


export default {
  components:{
    OffCanvas
  },
  setup(){
    const showCanvas = ref(false)
    const activeIndex = ref('1')
    const handleSelect = (key, keyPath)=>{
      console.log(key, keyPath)
    }
    const sidebar = ()=>{
      showCanvas.value = true
    }

    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    }

    return{
      activeIndex,
      handleSelect,
      showCanvas,
      sidebar,
      scrollToTop
    }
  }
}

</script>

<style scoped>
.tog {
  border: none;
  cursor: pointer;
}

.link {
  text-decoration: none;
  font-weight: normal;
  color: black;
}
@media (max-width: 576px) { 
    nav {
      margin-top: -8%; 
    }
  }
</style>
