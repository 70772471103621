import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '../node_modules/bootstrap/dist/css/bootstrap.css'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import VueLazyload from 'vue-lazyload'


const app = createApp(App)
app.use(store)
app.use(router)
app.use(VueLazyload)
app.use(ElementPlus)
app.mount('#app')
